import $ from 'jquery';
import anime from 'animejs/lib/anime.es';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import throttle from '../vendor/throttle';
import Overlay from '../vendor/overlay';

export function overlayMenu() {
  if (document.querySelector('[data-overlay="menu"]')) {
    const OVERLAY_MENU = new Overlay({
      name: 'menu',
      events: {
        open: true,
        close: true,
        openComplete: true,
      },
      click: {
        buttons: {
          toggle: '[data-toggle-overlay="menu"]',
          close: '[data-close-overlay="menu"], a[href]',
        },
      },
      options: {
        speed: 900,
        goToSelector: 'html, body',
      },
    });

    OVERLAY_MENU.init();

    const ON_OPEN_OVERLAY_SEARCH = () => {
      if (document.querySelector('html').classList.contains('show-overlay-menu')) {
        OVERLAY_MENU.close();
      }
    };

    OBSERVER.add({
      name: 'overlaySearch', events: 'onOpenOverlaySearch', function: ON_OPEN_OVERLAY_SEARCH,
    });
    OBSERVER.on('overlaySearch');
  }
}

export function overlaySearch() {
  if (document.querySelector('[data-overlay="search"]')) {
    const SEARCH_FIELD = document.querySelector('.js-search-input');
    const SEARCH_FIELDS = document.querySelectorAll('.js-search-input');
    const SEARCH_FIELDS_LENGTH = SEARCH_FIELDS.length;
    const MOST_SEARCHED = document.querySelector('.js-overlay-search-most-searched');
    const FAST_RESULTS = document.querySelector('.js-overlay-search-fast-results');
    let displayedView = 'most-searched';
    let isAnimating = false;

    const eventName = 'overlaySearchViewAnimationComplete';
    const viewAnimationComplete = new Event(eventName);

    FAST_RESULTS.style.opacity = 0;
    FAST_RESULTS.style.transform = 'translateX(100%)';

    const OVERLAY_SEARCH = new Overlay({
      name: 'search',
      events: {
        open: true,
        close: true,
        openComplete: true,
      },
      click: {
        buttons: {
          open: '[data-open-overlay="search"]',
          close: '[data-close-overlay="search"]',
          switch: '[data-trigger-overlay="dropdown"], [data-toggle-overlay="mobile-menu"], [data-toggle-overlay="calendar-toggle"]',
        },
      },
      options: {
        speed: 900 + 175,
        goToSelector: 'html, body',
        closeOnResize: false,
      },
    });

    OVERLAY_SEARCH.init();

    const ON_FOCUS = () => {
      OVERLAY_SEARCH.open();
    };

    const ON_RESIZE = () => {
      // Note de Charles: Je sais pas ce code là sert à quoi mais ça glitch solide en mobile quand le clavier apparait
      /* SEARCH_FIELDS.forEach((field) => {
        field.blur();
      }); */
    };

    const ON_OPEN = () => {
      document.querySelector('.js-search-button-header').setAttribute('form', 'headerSearchForm');
      document.querySelector('.js-search-button-header').setAttribute('type', 'submit');
    };

    const ON_CLOSE = () => {
      document.querySelector('.js-search-button-header').setAttribute('form');
      document.querySelector('.js-search-button-header').setAttribute('type', 'button');
    };

    const ON_OPEN_COMPLETE = () => {
      const WW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (WW <= 950) {
        document.querySelector('#form-search_input-mobile').focus();
      }
    };

    const ANIMATE = (name, objectView1, objectView2) => {
      isAnimating = true;
      anime.timeline({
        duration: 1200,
        easing: 'easeInOutQuart',
      }).add(objectView1).add(objectView2, '-=1000').finished.then(() => {
        displayedView = name;
        isAnimating = false;
        setTimeout(() => {
          dispatchEvent(viewAnimationComplete);
        }, 50);
      });
    };

    const DISPLAY_MOST_SEARCHED = () => {
      const objectView1 = { targets: FAST_RESULTS, opacity: 0, translateX: '100%' };
      const objectView2 = { targets: MOST_SEARCHED, opacity: 1, translateX: '0%' };
      ANIMATE('most-searched', objectView1, objectView2);
    };

    const DISPLAY_FAST_RESULTS = throttle(() => {
      if (displayedView === 'most-searched') {
        const objectView1 = { targets: MOST_SEARCHED, opacity: 0, translateX: '-100%' };
        const objectView2 = { targets: FAST_RESULTS, opacity: 1, translateX: '0%' };
        ANIMATE('fast-results', objectView1, objectView2);
      }
    }, 1000);

    const CALL_AJAX = throttle(() => {
      const searchValue = SEARCH_FIELD.value;
      if (searchValue.length > 3) {
        $.request('rechercheAutocomplete::onFetchAutocomplete', {
          data: { q: searchValue },
          update: { 'rechercheAutocomplete::autocomplete': '#fastResults' },
        });
      }
    }, 1000);

    const SWITCH_VIEW = () => {
      if (!isAnimating) {
        const SEARCH_FIELD_VALUE = SEARCH_FIELD.value;
        if (SEARCH_FIELD_VALUE.length <= 3 && displayedView === 'fast-results') {
          DISPLAY_MOST_SEARCHED();
        } else if (SEARCH_FIELD_VALUE.length > 3 && displayedView === 'most-searched') {
          DISPLAY_FAST_RESULTS();
        }
      }
    };

    const ON_INPUT_CHANGE = () => {
      CALL_AJAX();
    };

    const CLONE_INPUT = (e) => {
      const NEW_VALUE = e.target.value;

      let i;
      for (i = 0; i < SEARCH_FIELDS_LENGTH; i += 1) {
        SEARCH_FIELDS[i].value = NEW_VALUE;
      }

      SWITCH_VIEW(NEW_VALUE);
    };

    const ON_CLICK_SEARCH_BUTTON = (e) => {
      e.preventDefault();

      const HTML_CLASSLIST = document.querySelector('html').classList;
      if (!HTML_CLASSLIST.contains('show-overlay-search') && !HTML_CLASSLIST.contains('closing-overlay-search')) {
        OVERLAY_SEARCH.open();
      } else {
        document.querySelector('#headerForm').submit();
      }
    };

    OBSERVER.add({
      name: 'overlaySearch', events: 'resize', function: ON_RESIZE,
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'focus', function: ON_FOCUS, targets: '#form-search_input-desktop',
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'click', function: ON_CLICK_SEARCH_BUTTON, targets: '.js-search-button',
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'dynamicClearInputSearch', function: SWITCH_VIEW,
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'overlaySearchViewAnimationComplete', function: SWITCH_VIEW,
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'input', function: CLONE_INPUT, targets: SEARCH_FIELDS,
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'input', function: ON_INPUT_CHANGE,
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'onOpenCompleteOverlaySearch', function: ON_OPEN_COMPLETE,
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'onOpenOverlaySearch', function: ON_OPEN,
    });
    OBSERVER.add({
      name: 'overlaySearch', events: 'onCloseOverlaySearch', function: ON_CLOSE,
    });
    OBSERVER.on('overlaySearch');
  }
}

export function overlayPopup() {
  if (document.querySelector('[data-overlay="popup"]')) {
    const ovPopup = new Overlay({
      name: 'popup',
      click: {
        buttons: {
          close: '[data-overlay-close="popup"]',
        },
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 1200,
        closeOnResize: false,
      },
    });

    ovPopup.init();
  }
}

export function overlayRessource() {
  window.ressourceOverlay = new Overlay({
    name: 'ressource',
    click: {
      buttons: {
        close: '[data-js="close-ressource"]',
      },
    },
    timeout: {
      delay: 45000,
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  window.ressourceOverlay.init();
}

export function overlayShare() {
  if (document.querySelector('[data-overlay="share"]')) {
    const ovShare = new Overlay({
      name: 'share',
      click: {
        buttons: {
          open: '.js-share',
          close: '.close-overlay',
        },
      },
      options: {
        speed: 800,
      },
    });

    ovShare.init();

    // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    //   __sharethis__.init(__sharethis__.config);
    // }

    // Message de confirmation de copie de l'URL ----------------
    const confirmClipboardCopy = () => {
      setTimeout(() => {
        // eslint-disable-next-line no-alert
        alert('L’adresse URL a bien été copiée dans votre presse-papier.');
      }, 300);
    };

    OBSERVER.add({
      name: 'confirmClipboardCopy',
      events: 'click',
      targets: '.js-share-copy',
      function: confirmClipboardCopy,
    });
    OBSERVER.on('confirmClipboardCopy');

    // Gestion de l'ouverture de l'overlay ----------------------
    const openShareOverlay = (e) => {
      if (e.currentTarget.dataset.rubric) {
        window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
      }
      document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
      ShareButtons.update();
    };

    OBSERVER.add({
      name: 'openShareOverlay',
      events: 'click',
      targets: '.js-share',
      function: openShareOverlay,
    });
    OBSERVER.on('openShareOverlay');

    // Gestion de la fermeture de l'overlay ---------------------
    const closeShareOverlay = () => {
      window.history.replaceState(null, null, ' ');
      document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
      ShareButtons.update();
    };

    OBSERVER.add({
      name: 'closeShareOverlay',
      events: 'click',
      targets: '.overlay-share__background, .overlay-share__close',
      function: closeShareOverlay,
    });
    OBSERVER.on('closeShareOverlay');
  }
}

export function overlayCalendar() {
  if (document.querySelector('[data-overlay="calendar"]')) {
    const OVERLAY_CALENDAR = new Overlay({
      name: 'calendar',
      click: {
        buttons: {
          toggle: '[data-js="toggle-calendar"]',
          close: '[data-js="close-calendar"]',
          switch: '[data-js="toggle-search"]',
        },
      },
      options: {
        speed: 800,
        closeOnResize: false,
      },
    });

    OVERLAY_CALENDAR.init();

    const ON_OPEN_OVERLAY_SEARCH = () => {
      if (document.querySelector('html').classList.contains('show-overlay-calendar')) {
        OVERLAY_CALENDAR.close();
      }
    };

    OBSERVER.add({
      name: 'overlaySearch', events: 'onOpenOverlaySearch', function: ON_OPEN_OVERLAY_SEARCH,
    });
    OBSERVER.on('overlaySearch');
  }
}
