// Englobe les iframe de vidéos youtube pour gérer le ratio
function wrapYtVideo() {
  let i;
  let youtubeVideosOuterHtml;
  let newYoutubeVideosOuterHtml;
  const youtubeVideos = document.querySelectorAll('.s-cms-content iframe[src*="youtube.com"],.s-cms-content iframe[src*="vimeo.com"]');
  const youtubeVideosLength = youtubeVideos.length;

  for (i = 0; i < youtubeVideosLength; i += 1) {
    youtubeVideosOuterHtml = youtubeVideos[i].outerHTML;
    newYoutubeVideosOuterHtml = `<div class='videoWrapper'><div class='ytVideo'>${youtubeVideosOuterHtml}</div></div>`;
    youtubeVideos[i].outerHTML = newYoutubeVideosOuterHtml;
  }
}

// Ajouter des marges pour les <p> qui contiennent des boutons
function adjustButtonsMargins() {
  let i;
  const buttons = document.querySelectorAll('.s-cms-content p > a.primary-button, .s-cms-content p > a.secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i += 1) { buttons[i].parentNode.classList.add('buttons'); }

  const buttonsParagraph = document.querySelectorAll('.s-cms-content p.buttons');
  const buttonsParagraphLength = buttonsParagraph.length;

  for (i = 0; i < buttonsParagraphLength; i += 1) {
    if (buttonsParagraph[i].previousElementSibling !== null) {
      if (!buttonsParagraph[i].previousElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--first');
      }
    }
    if (buttonsParagraph[i].nextElementSibling !== null) {
      if (!buttonsParagraph[i].nextElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--last');
      }
    }
  }
}

// Ajouter un <span> dans les boutons pour l'animation
function addSpanToButtons() {
  let i;
  let buttonsInnerHtml;
  let newButtonsInnerHtml;
  const buttons = document.querySelectorAll('.s-cms-content .primary-button, .s-cms-content .secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i += 1) {
    buttonsInnerHtml = buttons[i].innerHTML;
    newButtonsInnerHtml = `<span>${buttonsInnerHtml}</span>`;
    buttons[i].innerHTML = newButtonsInnerHtml;
  }
}

// Ajouter les icones svg pour les boutons et les liens textes
function addSvgToLinks() {
  const themePath = window.config.theme_path;
  const sltr = '.s-cms-content a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])';
  const linksExternal = document.querySelectorAll(sltr);

  linksExternal.forEach((link) => {
    if (!link.querySelector('img')) {
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-external`,
      );
      svgElem.appendChild(useElem);
      link.appendChild(svgElem);
    }
  });

  const sltr2 = '.s-cms-content a[href$=".pdf"], .s-cms-content a[href$=".doc"], .s-cms-content a[href$=".zip"]';
  const linksDocuments = document.querySelectorAll(sltr2);
  linksDocuments.forEach((link) => {
    if (!link.querySelector('img')) {
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-download`,
      );
      svgElem.appendChild(useElem);
      link.appendChild(svgElem);
      link.setAttribute('target', '_blank');
    }
  });
}

// Ajouter les icones svg pour les boutons et les liens textes
function manageImageLinks() {
  const links = document.querySelectorAll('.s-cms-content a');
  links.forEach((link) => {
    if (link.querySelector('img')) {
      link.classList.add('link-image');
    }
  });
}

// Mettre des target _blank automatiquement sur les liens externes
function autoTargetBlank() {
  document.querySelectorAll('.s-cms-content a').forEach((link) => {
    if (link.hostname !== window.location.hostname) {
      link.setAttribute('target', '_blank');
    }
  });
}

// Le package à appliquer pour les zones dynamiques
export default function dynamicPackage() {
  wrapYtVideo();
  adjustButtonsMargins();
  autoTargetBlank();
  addSpanToButtons();
  addSvgToLinks();
  manageImageLinks();
}
