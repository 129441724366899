import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from './plugins';
import transition from './transitions/transition';
import {
  addWrapperToTextElement, changeSelect,
  backendKeyBind, calendar, customCursor, generateMosaic, hideButton, photoCredits,
  ogContent, searchFieldTyper, setDynamicVars, initShareButtonVisibility, showPopup, copyToClipboard,
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, textFieldValue, tailSelect,
} from './functions/form';
import {
  overlayCalendar,
  overlayMenu, overlayPopup, overlayRessource, overlaySearch, overlayShare,
} from './functions/overlays';
import overlayCookies from './functions/overlayCookies';
import Overlay from './vendor/overlay';
import { formContact, formExample, formNewsletter, formSuccess } from './functions/validation';
import masksPackage from './functions/masks';
import fontSizeBasedOnCaracters, { hundredVH, removeHoverOnMobile } from './functions/helper';
import dynamicClearInput from './functions/dynamicClearInput';
import splashScreen from './transitions/splash-screen';
import fastSplashScreen from './transitions/fast-splash-screen';
import {
  eventsSlider, homeBannerSlider, newsSlider, ressourcesSlider, partnersSlider,
} from './functions/sliders';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers,
} from './functions/map/map';
import { clickToScrollToBlock, dynamicScrollToBlock } from './functions/scrollToBlock';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
// window.overlayRessource = overlayRessource;

const initBarba = () => {
  const SPLASH = sessionStorage.splash === '1' ? fastSplashScreen() : splashScreen();

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    overlayShare();
    overlayMenu();
    overlaySearch();
    overlayPopup();
    overlayRessource();

    if (document.querySelector('[data-overlay="cookies"]')) {
      overlayCookies();
    }

    textFieldValue();
    dynamicClearInput();
    searchFieldTyper();
    backendKeyBind();
    ShareButtons.update();
    removeHoverOnMobile();
    setDynamicVars();
    addWrapperToTextElement();
    fontSizeBasedOnCaracters();
    dynamicScrollToBlock();
    initShareButtonVisibility();
    hundredVH();
  });

  barba.hooks.afterEnter(() => {
    customCursor();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    preventRunning: true,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [{ ...SPLASH, ...transition() }],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics();
          formsPackage();
          masksPackage();
          dynamicPackage();
          eventsSlider();
          newsSlider();
          partnersSlider();
          ressourcesSlider();
          formExample();
          photoCredits();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          dynamicPackage();
          homeBannerSlider();
          generateMosaic();
          newsSlider();
          photoCredits();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect();
          changeSelect('#news-select');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          newsSlider();
          photoCredits();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          tailSelect();
          calendar();
          overlayCalendar();
          changeSelect('#events-select');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventDetailView',
        beforeEnter() {
          dynamicPackage();
          eventsSlider();
          photoCredits();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          tailSelect();
          changeSelect('#provider-select');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'webCapsulesView',
        beforeEnter() {
          tailSelect();
          changeSelect('#capsules-select');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'directoryView',
        beforeEnter() {
          tailSelect();
          hideButton();
          overlayRessource();
          changeSelect('#categoryId');
          changeSelect('#themeId');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'directoryDetailView',
        beforeEnter() {
          dynamicPackage();
          ressourcesSlider();
          hideButton();
          overlayRessource();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          dynamicPackage();
          rubrics();
          formsPackage();
          masksPackage();
          formContact();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'formView',
        beforeEnter() {
          overlayRessource();
          copyToClipboard();
          showPopup();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          formNewsletter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'mapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
      {
        namespace: '404View',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '500View',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
