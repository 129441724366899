import anime from 'animejs/lib/anime.es';
import { overlayPopup } from '../functions/overlays';
import { SCROLLFIRE } from '../plugins';

export default function fastSplashScreen() {
  const SPLASH_SCREEN_CONTAINER = document.querySelector('.js-splash-screen-container');
  const SPLASH_SCREEN = document.querySelector('.js-splash-screen');
  const WORDS_CONTAINER = document.querySelector('.js-splash-screen-words');
  const WORDS = document.querySelectorAll('.js-splash-screen-word');
  let initStuff = false;

  return {
    beforeOnce: () => new Promise((resolve) => {
      // WORDS.forEach((word) => {
      //   const ELEMENT = word;
      //   ELEMENT.style.opacity = '1';
      //   ELEMENT.style.transform = 'translateY(0%)';
      // });
      // WORDS_CONTAINER.style.transform = 'translateY(0%)';
      // SPLASH_SCREEN_CONTAINER.style.display = 'block';
      resolve();
    }),

    once: () => new Promise((resolve) => {
      // anime.timeline({
      //   duration: 2000,
      //   easing: 'easeOutElastic',
      // }).add({
      //   targets: [SPLASH_SCREEN, WORDS_CONTAINER],
      //   translateY: '100%',
      //   duration: 1200,
      //   easing: 'easeInOutQuart',
      //   delay: 500,
      //   update: (anim) => {
      //     if (Math.round(anim.progress) >= 60 && !initStuff) {
      //       initStuff = true;
            SCROLLFIRE.init();
            overlayPopup();
      //     }
      //   },
      // }).finished.then(() => {
      //   SPLASH_SCREEN_CONTAINER.style.display = '';
        resolve();
      // });
    }),
  };
}
