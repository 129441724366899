import $ from 'jquery';
import typer from 'typer-js';
import gsap from 'gsap';
import MouseFollower from 'mouse-follower';
import { Datepicker } from 'vanillajs-datepicker';
import { OBSERVER } from '../plugins';
import { isMobile, isVisible } from './helper';

// Ajoute des 0 dans les dates du calendrier
function addZero() {
  const getDate = () => {
    const dates = document.querySelectorAll('.day');
    let date;
    let i;

    for (i = 0; i < dates.length; i += 1) {
      date = dates[i];

      if (date.innerHTML < 10) {
        const newDate = `0${date.innerHTML}`;
        date.innerHTML = newDate;
      }
    }
  };

  getDate();

  const targetNode = document.querySelector('.datepicker-cell');
  const config = {
    attributes: true, childList: true, subtree: true, characterData: true,
  };
  let mutationObserver;
  const callback = () => {
    getDate();

    mutationObserver.disconnect();
    mutationObserver.observe(targetNode, config);
  };

  mutationObserver = new MutationObserver(callback);
  mutationObserver.observe(targetNode, config);
}

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');
  const currentDate = document.querySelector('.js-calendar').dataset.date;

  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: 'fr',
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
    });

    // Fermer l'overlay quand on clique
    // document.querySelector('#overlayCalendar .wrapper a.close span.x').click();
  };

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');

  addZero();

  $('.js-calendar').datepicker('update', currentDate);
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function searchFieldTyper() {
  if (!document.querySelector('.js-typer')) return;

  const WORDS = document.querySelector('.js-typer').getAttribute('data-typer-words').split(',');
  const TYPER_INSTANCE = typer('.js-typer').line();

  WORDS.forEach((e) => {
    TYPER_INSTANCE.continue(e).pause(2000).back('all');
  });

  TYPER_INSTANCE.repeat(Infinity, true);
}

export function addWrapperToTextElement() {
  const CLASS_NAME = 'o-text-wrapper';
  const IGNORE_CLASS_NAME = 'js-no-text-wrapper';
  const IGNORE_TAG_NAME = ['option'];

  const ELEMENTS_WITH_TEXT = Array.from(document.body.querySelectorAll('*')).filter(
    (element) => Array.from(element.childNodes).find((node) => node.nodeType === 3 && node.textContent.trim().length > 1),
  );

  ELEMENTS_WITH_TEXT.forEach((element) => {
    let condition = true;

    IGNORE_TAG_NAME.forEach((tagName) => {
      if (element.tagName.toLowerCase() === tagName) {
        condition = false;
      }
    });

    if (!element.classList.contains(IGNORE_CLASS_NAME) && condition) {
      element.childNodes.forEach((node) => {
        if (typeof node.tagName === 'undefined') {
          const SPAN = document.createElement('span');
          const FONT_FAMILY_PROPERTY = window.getComputedStyle(element).getPropertyValue('font-family');
          const FONT_FAMILY = FONT_FAMILY_PROPERTY.substring(0, FONT_FAMILY_PROPERTY.indexOf(','));

          SPAN.classList.add(CLASS_NAME);
          if (FONT_FAMILY !== '') {
            SPAN.classList.add(`--${FONT_FAMILY}`);

            node.after(SPAN);
            SPAN.appendChild(node);
          }
        }
      });
    }
  });
}

export function setDynamicVars() {
  if (!document.querySelector('.js-header-grid')) return;
  let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  function setHeight() {
    const GRID = document.querySelector('.js-header-grid');
    const MENU_BUTTON = document.querySelector('.js-menu-button');
    const currentWw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (ww !== currentWw) {
      ww = currentWw;
    }
    const GRID_HEIGHT = GRID.clientHeight;
    const MENU_BUTTON_HEIGHT = MENU_BUTTON.clientHeight;
    const CALCUL = GRID_HEIGHT - MENU_BUTTON_HEIGHT;
    const RESULT = CALCUL > 0 ? CALCUL : 0;
    document.documentElement.style.setProperty('--x-header-height-grid', `${RESULT}px`);

    const HEADER = document.querySelector('.c-header');
    const HEADER_HEIGHT = HEADER.offsetHeight;
    document.documentElement.style.setProperty('--c-header_height', `${HEADER_HEIGHT}px`);
  }

  setHeight();

  OBSERVER.add({
    name: 'setVarHeaderHeightGrid',
    events: 'resize',
    targets: 'window',
    function: setHeight,
  });
  OBSERVER.on('setVarHeaderHeightGrid');

  setHeight();
}

export function generateMosaic() {
  let i;
  let columnCount;
  let activeLayout;
  let activeArray;
  let hasChange;

  const DESKTOP_LAYOUT = [0, 1, 2, 3, 0, 1, 2, 3, 0, 1, 2, 2];
  const TABLET_LAYOUT = [0, 1, 2, 0, 1, 2, 0, 1, 2, 2, 0, 1];
  const MOBILE_LAYOUT = [0, 1, 1, 0, 0, 0, 1, 0, 1, 1, 1, 0];

  const TEMPLATE = document.querySelector('.js-mosaic-template');
  const MOSAIC_AREA = document.querySelector('.js-mosaic-area');
  const CLONE = TEMPLATE.content.cloneNode(true);
  const LINKS = CLONE.querySelectorAll('a');

  const SET_LAYOUT = () => {
    const WINDOW_WIDTH = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (WINDOW_WIDTH > 900 && activeLayout !== 'desktop') {
      activeLayout = 'desktop';
      activeArray = DESKTOP_LAYOUT;
      columnCount = 4;
      hasChange = true;
    } else if (WINDOW_WIDTH <= 900 && WINDOW_WIDTH > 600 && activeLayout !== 'tablet') {
      activeLayout = 'tablet';
      activeArray = TABLET_LAYOUT;
      columnCount = 3;
      hasChange = true;
    } else if (WINDOW_WIDTH <= 600 && activeLayout !== 'mobile') {
      activeLayout = 'mobile';
      activeArray = MOBILE_LAYOUT;
      columnCount = 2;
      hasChange = true;
    }

    if (hasChange) {
      MOSAIC_AREA.innerHTML = '';

      const HTML_CONTENT = document.createElement('div');
      HTML_CONTENT.classList.add('c-mosaic__content__grid');

      for (i = 0; i < columnCount; i += 1) {
        const ITEM = document.createElement('div');
        ITEM.classList.add('c-mosaic__content__item');
        HTML_CONTENT.appendChild(ITEM);
      }

      for (i = 0; i < activeArray.length; i += 1) {
        const COLUMN_INDEX = activeArray[i];
        const COLUMN_ELEMENT = HTML_CONTENT.querySelectorAll('.c-mosaic__content__item')[COLUMN_INDEX];
        const LINK = LINKS[i];
        COLUMN_ELEMENT.appendChild(LINK);
      }

      MOSAIC_AREA.appendChild(HTML_CONTENT);

      hasChange = false;
    }
  };

  SET_LAYOUT();

  OBSERVER.add({
    name: 'generateMosaic',
    events: 'resize',
    targets: 'window',
    function: SET_LAYOUT,
  });
  OBSERVER.on('generateMosaic');
}

export function customCursor() {
  // if (!isMobile()) {
  //   MouseFollower.registerGSAP(gsap);

  //   window.cursor = new MouseFollower({
  //     stateDetection: {
  //       '-pointer': 'a,button',
  //       '-opaque': 'a,button',
  //     },
  //   });

  //   const ADD_INVERSE = () => {
  //     window.cursor.addState('-inverse');
  //   };
  //   const REMOVE_INVERSE = () => {
  //     window.cursor.removeState('-inverse');
  //   };
  //   const ADD_TRANPARENT = () => {
  //     window.cursor.addState('-transparent');
  //   };
  //   const REMOVE_TRANPARENT = () => {
  //     window.cursor.removeState('-transparent');
  //   };

  //   OBSERVER.add({
  //     name: 'customCursor', events: 'mouseenter', targets: '.mf-inverse', function: ADD_INVERSE,
  //   });
  //   OBSERVER.add({
  //     name: 'customCursor', events: 'mouseleave', targets: '.mf-inverse', function: REMOVE_INVERSE,
  //   });
  //   OBSERVER.add({
  //     name: 'customCursor', events: 'mouseenter', targets: 'a,button', function: ADD_TRANPARENT,
  //   });
  //   OBSERVER.add({
  //     name: 'customCursor', events: 'mouseleave', targets: 'a,button', function: REMOVE_TRANPARENT,
  //   });
  //   OBSERVER.on('customCursor');

  //   // const btn = document.querySelectorAll('[data-magnetic]');
  //   // const update = (e) => {
  //   //   const span = e.currentTarget.querySelector('a,button');

  //   //   if (e.type === 'mouseleave') {
  //   //     span.style.cssText = '';
  //   //   } else {
  //   //     const { offsetX: x, offsetY: y } = e;
  //   //     const { offsetWidth: width, offsetHeight: height } = e.currentTarget;
  //   //     const walk = 10;
  //   //     const xWalk = (x / width) * (walk * 2) - walk;
  //   //     const yWalk = (y / height) * (walk * 2) - walk;

  //   //     span.style.cssText = `transform: translate(${xWalk}px, ${yWalk}px);`;
  //   //   }
  //   // };

  //   // btn.forEach((b) => b.addEventListener('mousemove', update));
  //   // btn.forEach((b) => b.addEventListener('mouseleave', update));
  // }
}

export function hideButton() {
  const button = document.querySelector('[data-js="button-to-hide"]');
  const elements = document.querySelectorAll('.c-ressources-slider, .c-showcase');
  let i;

  const hide = () => {
    for (i = 0; i < elements.length; i += 1) {
      const element = elements[i];

      if (button !== null) {
        if (typeof element !== 'undefined' || element !== null) {
          if (isVisible(element)) {
            button.classList.add('hidden');
          } else if (button.classList.contains('hidden')) {
            button.classList.remove('hidden');
          }
        }
      }
    }
  };

  hide();

  OBSERVER.add({
    name: 'hideButton',
    events: 'scroll',
    targets: 'window',
    function: hide,
  });
  OBSERVER.on('hideButton');
}

export function changeSelect(select) {
  $(select).next('.tail-select').find('.dropdown-option').on('click', function() {
    $(select).trigger('change');
  });
}

function manageButtonsVisibility() {
  const element = document.querySelector('.c-footer');

  if (typeof element !== 'undefined' && element !== null) {
    if (isVisible(element)) {
      document.querySelector('html').classList.add('hide-buttons');
    } else {
      document.querySelector('html').classList.remove('hide-buttons');
    }
  }
}

export function initShareButtonVisibility() {
  OBSERVER.add({
    name: 'manageButtonsVisibility',
    events: 'scroll',
    function: manageButtonsVisibility,
  });

  manageButtonsVisibility();
  OBSERVER.on('manageButtonsVisibility');
}

export function photoCredits() {
  const hoverItems = document.querySelectorAll('.photo-credits__hover-item');

  function touch(e) {
    const isCreditsVisible = e.currentTarget.nextElementSibling.classList.contains('visible-credits');
    if (isCreditsVisible) {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits');
    } else {
      e.currentTarget.nextElementSibling.classList.add('visible-credits');
    }
  }

  if (!isMobile()) {
    const hoverIn = (e) => {
      e.currentTarget.nextElementSibling.classList.add('visible-credits');
    };
    const hoverOut = (e) => {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits');
    };

    OBSERVER.add({
      name: 'hover',
      events: 'mouseenter',
      targets: hoverItems,
      function: hoverIn,
    });
    OBSERVER.add({
      name: 'hover',
      events: 'mouseleave',
      targets: hoverItems,
      function: hoverOut,
    });
    OBSERVER.on('hover');
  } else if (isMobile()) {
    OBSERVER.add({
      name: 'touch',
      events: 'click',
      targets: hoverItems,
      function: touch,
    });
    OBSERVER.on('touch');
  }
}

export function showPopup() {
  function flashPopup() {
    const POPUP = document.querySelector('.js-popup-bubble');

    function changeOpacity(element, opacity) {
      element.style.opacity = opacity;
    }

    changeOpacity(POPUP, 1);

    setTimeout(changeOpacity, 3000, POPUP, 0);
  }

  OBSERVER.add({
    name: 'flashPopup',
    events: 'click',
    targets: '.js-show-popup-bubble',
    function: flashPopup,
  });
  OBSERVER.on('flashPopup');
}

export function copyToClipboard() {
  function copyClipboard(e) {
    const text = `${window.location.href}?ids=${e.currentTarget.dataset.ids}`;
    navigator.clipboard.writeText(text);
  }
  OBSERVER.add({
    name: 'copyClipboard',
    events: 'click',
    targets: '.js-copy-clipboard',
    function: copyClipboard,
  });
  OBSERVER.on('copyClipboard');
}
