// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
import Swiper from 'swiper/bundle';

export function homeBannerSlider() {
  const sliderName = 'home-banner';
  const totalSlidesLength = document.querySelectorAll(`[data-swiper="${sliderName}"] .swiper-slide`).length;
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: totalSlidesLength >= 2,
    autoplay: {
      delay: 5000,
    },
  });
}

export function newsSlider() {
  const sliderName = 'news';
  const totalSlidesLength = document.querySelectorAll(`[data-swiper="${sliderName}"] .swiper-slide`).length;
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: totalSlidesLength >= 4,
    navigation: {
      prevEl: `[data-swiper-prev="${sliderName}"]`,
      nextEl: `[data-swiper-next="${sliderName}"]`,
    },
  });
}

export function eventsSlider() {
  const sliderName = 'events';
  const totalSlidesLength = document.querySelectorAll(`[data-swiper="${sliderName}"] .swiper-slide`).length;
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: totalSlidesLength >= 4,
    navigation: {
      prevEl: `[data-swiper-prev="${sliderName}"]`,
      nextEl: `[data-swiper-next="${sliderName}"]`,
    },
  });
}

export function ressourcesSlider() {
  const sliderName = 'ressources';
  const totalSlidesLength = document.querySelectorAll(`[data-swiper="${sliderName}"] .swiper-slide`).length;
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: totalSlidesLength >= 4,
    navigation: {
      prevEl: `[data-swiper-prev="${sliderName}"]`,
      nextEl: `[data-swiper-next="${sliderName}"]`,
    },
  });
}

export function partnersSlider() {
  const sliderName = 'partners';
  const totalSlidesLength = document.querySelectorAll(`[data-swiper="${sliderName}"] .swiper-slide`).length;
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: totalSlidesLength >= 4,
    navigation: {
      prevEl: `[data-swiper-prev="${sliderName}"]`,
      nextEl: `[data-swiper-next="${sliderName}"]`,
    },
  });
}
