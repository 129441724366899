/*
Gére les overlays
Écoute les événements de la carte pour réagir
 */

import $ from 'jquery'
import { flipText } from '../functions'
//import UI from '../classes/UI.js'
import {mapUrl} from './mapUrl'

export class mapOverlay {

  static init() {

    window.addEventListener('marker.panel.show', (e) => {
      this.manageSidePanel(e.detail.id)
    })

    window.addEventListener('panel.hide', (e) => {
      this.hideSidePanel()
    })
  }

  /**
   * Charger les infos du side panel
   */
  static manageSidePanel(id) {
    
    // si le panel n’est pas affiché, on procede au chargement direct
    // sinon on ferme, puis on charge
    if ( this.isSidePanelOpen() ) {
      this.hideSidePanel()
      setTimeout( () => this.getPOI(id),400)
    } else {
      this.getPOI(id)
    }
  }

  /**
   * Ouvrir le side Panel et gérer les infos
   */
  static showSidePanel() {

    //UI.overlayMapDetail.open()
    window['overlayLocation'].open()
    flipText();
  }

  /**
   * Fermer le side Panel
   */
  static hideSidePanel() {

    //UI.overlayMapDetail.close()
    window['overlayLocation'].close()
  }

  static isSidePanelOpen() {

    //return UI.overlayMapDetail.isOpen()
    if ( $('html').hasClass('showOverlayLocation') ) return true
    return false
  }

  /**
   * Récupère les infos du POI
   */
  static getPOI(id) {

    $.request('carteliste::onRefreshPOI', {
      data: {
        id: id,
        catslug: mapUrl.getSlugCategory(),
        subcatslug: mapUrl.getSlugSubCategory(),
      },
      update: {'carteliste::overlayDetail': '#overlayLocation'},
      complete: () => { this.showSidePanel() },
      flash: 1,
      handleFlashMessage: function (message, type) {
        $.oc.flashMsg({text: message, class: type})
      }
    })
  }
}

window.mapOverlay = mapOverlay