import anime from 'animejs/lib/anime.es';
import { SCROLLFIRE } from '../plugins';

export default function transition() {
  const TARGET_CONTAINER = document.querySelector('.js-transition-container');
  const TARGET = document.querySelector('.js-transition');
  const duration = 800;
  const easing = 'easeInOutQuart';
  let initStuff = false;

  return {
    leave: () => new Promise((resolve) => {
      TARGET.style.display = 'block';

      anime
        .timeline({
          targets: TARGET_CONTAINER,
          duration,
          easing,
        })
        .add({
          opacity: 1,
        })
        .finished.then(() => {
          initStuff = false;
          SCROLLFIRE.destroy();
          resolve();
        });
    }),
    // Le rideau disparaît par en haut
    enter: () => new Promise((resolve) => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      anime
        .timeline({
          targets: TARGET_CONTAINER,
          duration,
          easing,
        })
        .add({
          opacity: '0',
          update: (anim) => {
            if (Math.round(anim.progress) >= 10 && !initStuff) {
              initStuff = true;
              SCROLLFIRE.init();
            }
          },
        })
        .finished.then(() => {
          TARGET_CONTAINER.style.opacity = '';
          TARGET.style.display = '';
          resolve();
        });
    }),
  };
}
