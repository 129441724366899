import createCookie from './createCookie';
import getCookie from './getCookie';

/*
 * Fonction gérant l'overlay cookies (overlay custom)
 * On n'utilise pas l'Observer et la classe Overlay pour assurer la compatibilité avec d'anciens sites.
 */
export default function overlayCookies() {
  const html = document.querySelector('html');
  const overlay = document.querySelector('[data-overlay="cookies"]');
  const cookiesBtn = document.querySelector('.js-button-cookies');
  const closeBtn = document.querySelector('.js-overlay-cookies-button-close');
  const refuseBtn = document.querySelector('.js-overlay-cookies-button-refuse');
  const acceptBtn = document.querySelector('.js-overlay-cookies-button-accept');
  const openDelay = 1000;
  const closeDuration = 800;

  // Fonction pour ouvrir l'overlay
  const open = () => {
    html.classList.add(`show-overlay-${overlay.dataset.overlay}`);
  };

  // Fonction pour fermer l'overlay
  const close = () => {
    html.classList.add(`closing-overlay-${overlay.dataset.overlay}`);

    setTimeout(() => {
      html.classList.remove(`show-overlay-${overlay.dataset.overlay}`, `closing-overlay-${overlay.dataset.overlay}`);
    }, closeDuration);
  };

  // Ouvre l'overlay lors du chargement de la page avec un délai
  if (getCookie('cookiesAuthorizationStatus') === null) {
    setTimeout(open, openDelay);
  }

  // Event listener pour les boutons
  cookiesBtn.addEventListener('click', open);
  closeBtn.addEventListener('click', () => {
    createCookie('cookiesAuthorizationStatus', 'refuse', 365);
    close();
  });
  refuseBtn.addEventListener('click', () => {
    createCookie('cookiesAuthorizationStatus', 'refuse', 365);
    close();
  });
  acceptBtn.addEventListener('click', () => {
    createCookie('cookiesAuthorizationStatus', 'accept', 365);
    close();
  });
}
